html {
    height: 100%;
}

.grecaptcha-badge {
    /* Hides the Google reCaptcha badge */
    visibility: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter', 'Inter-Medium' sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: 100%;
}


#root{
    display: flex;
    flex-direction: column;
    height: 100%;
}

a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'VodafoneR';
    src: local('VodafoneR'), url('./UI/Assets/Fonts/Vodafone_Rg.ttf') format('truetype');
}

@font-face {
    font-family: 'VodafoneB';
    src: local('VodafoneB'), url('./UI/Assets/Fonts/Vodafone_Rg_Bold.ttf');
}

@font-face {
    font-family: 'VodafoneExB';
    src: local('VodafoneExB'), url('./UI/Assets/Fonts/VodafoneExB.ttf');
}

@font-face {
    font-family: 'VodafoneL';
    src: local('VodafoneL'), url('./UI/Assets/Fonts/Vodafone_Lt.ttf');
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('./UI/Assets/Fonts/Inter-Regular.ttf');
}
